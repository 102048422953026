#header-nav {
  background: $gray;
}

#header-nav .seperator:after {
  content: "|";
  font-size: 0.75rem;
  margin: 0 1rem;
}

#header-nav .dropdown-menu-custom {
  margin: 1rem 0 !important;
  background-color: $gray !important;
  border-radius: 0.25rem;
}

#header-nav .dropdown-item {
  color: #FFFFFF;
}

#header-nav .dropdown-item:last-of-type {
  border: none !important;
}

#header-nav .dropdown-item:hover,
#header-nav .dropdown-item:focus {
  color: $red;
  background-color: $gray;
  text-decoration: none;
}

#header-search img {
  width: 16vw;
}

header form button[type=submit] {
  background: #eaeaea;
  color: $gray-700;
  border-radius: 0;
  padding: 0 15px 0 0;
  height: 2.5rem !important;
  right: 0;
  top: 0;
  font-size: 1.2rem;
}
header form input[type=text] {
  border-radius: 0;
  border: 0;
  font-weight: 900;
  background: #eaeaea;
  color: #0F0F0F;
  text-transform: uppercase;
  font-size: 1.15rem;
  height: 2.5rem !important;
  padding: 0.375rem 0.875rem;
}

header form input[type=text]:focus {
  outline: 0;
  background: #eaeaea;
  box-shadow: 0 0 0 0.2rem rgb(227 6 19 / 25%);
}

#header-products {
  background-color: $red;
  box-shadow: inset 0 12px 20px rgb(0 0 0 / 50%);
  position: relative;
  padding: 0 8vw !important;
  margin-left: -5vw !important;
  width: 110vw !important;
}

#navbarMobile > div {
  position: relative;
  margin-left: -5vw !important;
  width: 110vw !important;
}
#navbarMobile ul li a {
  transition: ease all 0.25s;
}
#navbarMobile ul li a:hover {
  color: #3C3C3B !important;
  transition: ease all 0.25s;
}

#navbarMobile ul li div a {
  font-size: 0.92rem;
  color: #dddddd !important;
  line-height: 2rem;
  font-weight: 900;
  transition: ease all 0.25s;
}

#navbarMobile ul li div a:hover {
  color: $red !important;
  transition: ease all 0.25s;
}

#navbarMobile > div:first-of-type {
  background-color: $red;
  box-shadow: inset 0 12px 20px rgb(0 0 0 / 50%);
}

#header-products .seperator::after {
  content: "|";
  font-size: 1rem;
  margin: 0 4rem;
  opacity: 0.3;
}

#header-products li:last-of-type a.seperator::after {
  content: "";
}



