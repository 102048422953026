footer {
  background-image: url("/img/footer.png");
  background-position: center center;
  background-size: cover;
  position: relative;
}
footer:before {
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
footer .footer-logo {
  max-width: 150px;
}
footer .footer-item {
  display: block;
  color: #ffffff;
  font-size: 0.8rem;
  transition: ease all 0.25s;
}
footer .footer-item:hover {
   color: #E30613;
   text-decoration: none;
 }
footer .footer-bottom a:last-of-type:after {
  content: "" !important;
}

footer .language-btn {
  background-color: #636361;
  color: #ffffff;
  text-transform: none;
  font-size: 1rem;
  position: relative;
  text-align: left;
  transition: all 0.5s ease;
}
footer .language-btn img {
  width: 56px;
  height: 20px;
}
footer .language-btn {
  color: $black;
}
footer .language-select .dropdown-menu {
  background-color: #ffffff;
  border-radius: 0;
  top: -4px !important;
  transition: ease all 0.25s;
}
footer .language-select .dropdown-menu:hover .dropdown-item {
  background-color: transparent;
}
footer .language-select .dropdown-menu .dropdown-item {
  background-color: transparent;
  font-size: 1rem;
  transition: ease all 0.25s;
}
footer .language-select .dropdown-menu .dropdown-item:hover {
  background-color: #eaeaea;
}
footer a,
footer span {
  color: #ffffff;
  font-size: 0.9rem;
  transition: ease all 0.25s;
}
footer .dropdown-menu span {
  color: $black;
}
footer a:hover {
  color: $red;
  transition: ease all 0.25s;
}
footer a.seperator:after {
  content: "|";
  color: #FFFFFF;
  margin: 0 .5rem;
}
