#plp .card {
  flex: 1 0 calc(33% - 30px);
  margin: 15px;
  max-width: calc(33% - 30px);
}

#plp .card-link {
  box-shadow: 0 0 15px 3px rgb(0 0 0 / 15%);
  border-radius: 0;
  border: 0;
  transition: all ease 0.25s;
}

#plp .card-header {
  color: #FFFFFF;
  background: #5F9AC6;
  left: -10px;
  width: calc(100% + 10px);
}

#plp .card-header::after {
  background-color: #6f6f6e;
  content: "";
  height: 10px;
  width: 10px;
  bottom: -10px;
  left: 0;
  position: absolute;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}

#plp .card-image img {
  width: 180px;
  height: 180px;
}

#plp .card-footer {
  background: #85b2d3;
}

#plp .card button {
  padding: 1rem 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: unset;
  border: 0;
  font-size: 1rem;
  z-index: 1;
  color: #ffffff;
  background-color: #5F9AC6;
  position: relative;
  transition: all 0.5s ease;
}

#plp .card button::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom right;
  transform: skew(35.5deg, 0deg);
  transition: all 0.5s ease;
  background: #5F9AC6;
}

#plp .card .card-link:hover button,
#plp .card .card-link:hover button::after {
  /* background: #3C3C3B; */
  transform-origin: bottom right;
  transform: skew(0deg, 0deg);
}