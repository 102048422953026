@import url("/css/contentbuilder/assets/ionicons/css/ionicons.min.css");

#pages img {
  max-width: 100%;
  height: auto;
}

#pages .display {
  margin-bottom: 0.5rem;
}

#pages .container {
  margin: 0 auto;
  max-width: 100%;
}

#pages .column {
  position: relative;
}

#pages .card {
  margin: 15px;
}
#pages .card a {
  display: flex;
  height: 100%;
  flex-direction: column;
}

#pages .card-body {
  padding: 2rem;
}

#pages .card-footer {
  background-color: #85b2d3;
  border-top: 1px solid #85b2d3;
  padding: 0;
}

#pages .card-footer button {
  padding: 1rem 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: unset;
  border: 0;
  font-size: 1rem;
  z-index: 1;
  color: #ffffff;
  background-color: #5F9AC6;
  position: relative;
  transition: all 0.5s ease;
  top: 0;
}

#pages .card-footer button::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom left;
  transform: skew(-35.5deg, 0deg);
  transition: all 0.5s ease;
  background: #5F9AC6;
}

#pages .card-footer button:hover,
#pages .card-footer button:hover::after {
  background: #3C3C3B;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
}

.spacer {
  width: 100%
}

.height-20 {
  height: 20px
}

.height-40 {
  height: 40px
}

.height-60 {
  height: 60px
}

.height-80 {
  height: 80px
}

.height-100 {
  height: 100px
}

.height-120 {
  height: 120px
}

.height-140 {
  height: 140px
}

.height-160 {
  height: 160px
}

.height-180 {
  height: 180px
}

.height-200 {
  height: 200px
}

.height-220 {
  height: 220px
}

.height-240 {
  height: 240px
}

.height-260 {
  height: 260px
}

.height-280 {
  height: 280px
}

.height-300 {
  height: 300px
}

.padding-0 {
  padding: 0px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-20 {
  padding: 20px !important;
}

.padding-30 {
  padding: 30px !important;
}

.padding-40 {
  padding: 40px !important;
}

.padding-50 {
  padding: 50px !important;
}

.padding-60 {
  padding: 60px !important;
}

.margin-0 {
  margin: 0 !important
}

.margin-20 {
  margin: 20px !important
}

.margin-25 {
  margin: 25px !important
}

.margin-30 {
  margin: 30px !important
}

.margin-35 {
  margin: 35px !important
}

.margin-40 {
  margin: 40px !important
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  margin-top: 1.4em;
  margin-bottom: 1em;
}

.embed-responsive.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.embed-responsive iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.size-12 {font-size:12px !important}
.size-14 {font-size:14px !important}
.size-16 {font-size:16px !important}
.size-18 {font-size:18px !important}
.size-21 {font-size:21px !important}
.size-24 {font-size:24px !important}
.size-28 {font-size:28px !important}
.size-32 {font-size:32px !important}
.size-35 {font-size:35px !important}
.size-38 {font-size:38px !important}
.size-42 {font-size:42px !important}
.size-46 {font-size:46px !important}
.size-48 {font-size:48px !important}
.size-50 {font-size:50px !important}
.size-54 {font-size:54px !important}
.size-60 {font-size:60px !important}
.size-64 {font-size:64px !important}
.size-68 {font-size:68px !important}
.size-72 {font-size:72px !important}
.size-76 {font-size:76px !important}
.size-80 {font-size:80px !important}
.size-84 {font-size:84px !important}
.size-88 {font-size:88px !important}
.size-92 {font-size:92px !important}
.size-96 {font-size:96px !important}
.size-100 {font-size:100px !important}
.size-104 {font-size:104px !important}
.size-108 {font-size:108px !important}
.size-112 {font-size:112px !important}
.size-116 {font-size:116px !important}
.size-120 {font-size:120px !important}
.size-124 {font-size:124px !important}
.size-128 {font-size:128px !important}
.size-132 {font-size:132px !important}
.size-136 {font-size:136px !important}
.size-140 {font-size:140px !important}
.size-144 {font-size:144px !important}
.size-148 {font-size:148px !important}
.size-152 {font-size:152px !important}
.size-156 {font-size:156px !important}
.size-160 {font-size:160px !important}
.size-164 {font-size:164px !important}
.size-168 {font-size:168px !important}
.size-172 {font-size:172px !important}
.size-176 {font-size:176px !important}
.size-180 {font-size:180px !important}
.size-184 {font-size:184px !important}
.size-188 {font-size:188px !important}
.size-192 {font-size:192px !important}
.size-196 {font-size:196px !important}
.size-200 {font-size:200px !important}
.size-204 {font-size:204px !important}
.size-208 {font-size:208px !important}
.size-212 {font-size:212px !important}
.size-216 {font-size:216px !important}
.size-220 {font-size:220px !important}
.letter-spacing-25 {letter-spacing:25px !important}
.letter-spacing-24 {letter-spacing:24px !important}
.letter-spacing-23 {letter-spacing:23px !important}
.letter-spacing-22 {letter-spacing:22px !important}
.letter-spacing-21 {letter-spacing:21px !important}
.letter-spacing-20 {letter-spacing:20px !important}
.letter-spacing-19 {letter-spacing:19px !important}
.letter-spacing-18 {letter-spacing:18px !important}
.letter-spacing-17 {letter-spacing:17px !important}
.letter-spacing-16 {letter-spacing:16px !important}
.letter-spacing-15 {letter-spacing:15px !important}
.letter-spacing-14 {letter-spacing:14px !important}
.letter-spacing-13 {letter-spacing:13px !important}
.letter-spacing-12 {letter-spacing:12px !important}
.letter-spacing-11 {letter-spacing:11px !important}
.letter-spacing-10 {letter-spacing:10px !important}
.letter-spacing-9 {letter-spacing:9px !important}
.letter-spacing-8 {letter-spacing:8px !important}
.letter-spacing-7 {letter-spacing:7px !important}
.letter-spacing-6 {letter-spacing:6px !important}
.letter-spacing-5 {letter-spacing:5px !important}
.letter-spacing-4 {letter-spacing:4px !important}
.letter-spacing-3 {letter-spacing:3px !important}
.letter-spacing-2 {letter-spacing:2px !important}
.letter-spacing-1 {letter-spacing:1px !important}