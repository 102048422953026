#pdp-section-1 .carousel-control-next {
  justify-content: end;
}
#pdp-section-1 .carousel-control-prev {
  justify-content: start;
}
#pdp-section-1 #colors input[type=radio] {
  position: absolute;
  top: -10px;
  visibility: hidden;
}

#pdp-section-1 #colors label {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  border: 2px solid transparent;
  cursor: pointer;
}

#pdp-section-1 #colors label span {
  border-radius: 50%;
  border: 3px solid transparent;
}

#pdp-section-1 #colors input[type=radio]:checked + label {
  border: 2px solid #d5d5d4;
}

#pdp-section-1 #colors input[type=radio]:checked + label span {
  border: 3px solid white;
}

#pdp-section-1 .custom-select {
  background-color: #eaeaea;
  border-radius: 0;
  border: 1px solid #d5d5d4;
  height: 60px;
  font-size: 1rem;
}

#pdp-section-1 .custom-select:focus {
  box-shadow: none;
}

#pdp-section-2 nav a {
  background: none;
  position: relative;
  border: none;
  font-weight: normal;
  padding: 0;
  margin: 0;
}

#pdp-section-2 nav a span {
  background-color: #d1d1d1;
  text-align: center;
  margin-left: 50px;
  padding: 10px 50px 10px 0;
  position: relative;
  transition: all .25s ease;
  z-index: 2;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 900;
}

#pdp-section-2 nav a::after {
  background: #d1d1d1;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(-35.5deg, 0deg);
  transform-origin: bottom right;
  transition: all .25s ease;
  width: calc(100% - 50px);
  z-index: 1;
}

#pdp-section-2 nav a.active span,
#pdp-section-2 nav a.active::after {
  background: #F5F5F5;
}

#pdp-section-2 nav a:hover span,
#pdp-section-2 nav a:hover::after {
  background-color: #E30613;
  transition: all .25s ease;
  color: #FFFFFF;
}

#pdp-section-3 {
  background-color: #F5F5F5;
}

#pdp-section-3 .download a span:first-of-type {
  background-color: #E30613;
  font-size: 1.5rem;
  color: #ffffff;
  transition: ease all 0.25s;
}

#pdp-section-3 .download a span:last-of-type {
  background-color: #dddddd;
  font-size: .9rem;
  font-weight: bold;
  line-height: 1.2rem;
}

#pdp-section-3 .download a:hover span:first-of-type {
  background-color: #3C3C3B;
  transition: ease all 0.25s;
}
