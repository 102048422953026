#carouselCaptions .carousel-indicators {
  justify-content: left;
}

#carouselCaptions .carousel-item:after {
  content: "";
  position: absolute;
  top: 0;
  right: calc(50% + 90px);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform-origin: 100% 100%;
  transform: skewX(-35.5deg);
  z-index: 1;
}
#carouselCaptions .carousel-caption {
   text-align: left;
   font-size: 1rem;
   top: 50%;
   z-index: 10;
   transform: translateY(-50%);
   bottom: inherit;
   padding: 0;
   width: calc(40% - 60px);
   word-break: break-word;
 }

#carouselCaptions button {
  padding: 1rem 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: unset;
  border: 0;
  font-size: 1rem;
  z-index: 1;
  color: #ffffff;
  background-color: #5F9AC6;
  position: relative;
  transition: all 0.5s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
  -webkit-perspective: none;
}

#carouselCaptions button::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom left;
  transform: skew(-35.5deg, 0deg);
  transition: all 0.5s ease;
  background: #5F9AC6;
}

#carouselCaptions button:hover,
#carouselCaptions button:hover::after {
  background: #3C3C3B;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
}

#carouselCaptions .carousel-control-prev-icon,
#carouselCaptions .carousel-control-next-icon,
#carouselImages .carousel-control-prev-icon,
#carouselImages .carousel-control-next-icon {
  width: 40px;
  height: 40px;
}
#carouselCaptions .carousel-control-prev-icon,
#carouselImages .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3Csvg id='Component_30_3' data-name='Component 30 – 3' xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Ccircle id='Ellipse_23' data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg id='Group_335' data-name='Group 335' transform='translate(18.85 16.567)'%3E%3Crect id='Rectangle_906' data-name='Rectangle 906' width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)' fill='%23fff'/%3E%3Crect id='Rectangle_907' data-name='Rectangle 907' width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
}
#carouselCaptions .carousel-control-next-icon,
#carouselImages .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cg id='Component_30_4' data-name='Component 30 – 4' transform='translate(50 50) rotate(180)'%3E%3Ccircle id='Ellipse_23' data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg id='Group_335' data-name='Group 335' transform='translate(19.85 16.567)'%3E%3Crect id='Rectangle_906' data-name='Rectangle 906' width='12.462' height='3.116' transform='translate(0 8.812) rotate(-45)' fill='%23fff'/%3E%3Crect id='Rectangle_907' data-name='Rectangle 907' width='12.462' height='3.116' transform='translate(2.203 6.621) rotate(45)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

