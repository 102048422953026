#bannerCaptions .carousel-item::after {
  content: "";
  position: absolute;
  top: 0;
  right: calc(50% + 90px);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform-origin: 100% 100%;
  transform: skewX(-35.5deg);
  z-index: 1;
}
#bannerCaptions .carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 86%;
  width: 100%;
  height: 100%;
/*  background-color: rgba(227, 6, 19, .6); */
  transform-origin: 100% 100%;
  transform: skewX(-35.5deg);
  z-index: 2;
}
#bannerCaptions .carousel-caption {
  text-align: left;
  font-size: 1rem;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  bottom: inherit;
  padding: 0;
  width: calc(40% - 60px);
  word-break: break-word;
  right: 6%;
  left: 6%;
}
#breadcrumb a, #breadcrumb span {
  font-size: .9rem;
}
#breadcrumb i {
  font-size: .6rem;
}
