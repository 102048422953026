#contact {
  font-size: 1.5rem;
}

#contact h1 {
  font-size: 2.1875rem;
  font-weight: bold;
}

#contact h2 {
  font-size: 1.875rem;
  font-weight: bold;
}

#contact .details {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#contact form {
  background-color: #3C3C3B;
  color: #FFFFFF;
}

#contact label,
#contact label span,
#contact label a {
  font-size: 0.8rem;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: none;
}

#contact .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  /* background: #eaeaea; */
  border-radius: 0;
  border: 0;
  height: 60px;
}

#contact .form-control:focus {
  outline: 0;
  background: #eaeaea;
  box-shadow: 0 0 0 0.2rem rgb(227 6 19 / 25%);
}

#contact textarea {
  height: 120px !important;
}

#contact button {
  padding: 1rem 1.25rem;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0;
  letter-spacing: unset;
  border: 0;
  font-size: 1rem;
  z-index: 1;
  color: #ffffff;
  background-color: #E30613;
  position: relative;
  transition: all 0.5s ease;
}

#contact button::after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: bottom left;
  transform: skew(-35.5deg, 0deg);
  transition: all 0.5s ease;
  background: #E30613;
}

#contact button:hover,
#contact button:hover::after {
  background: #3C3C3B;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
}

