.swiper {
  width: 100%;
  height: 100%;
  padding: 40px !important;
}

.swiper-slide {
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-button-next,
.swiper-button-prev {
  width: 50px !important;
  height: 100% !important;
  position: absolute;
  top: 0 !important;
  opacity: .5;
}

.swiper-button-disabled {
  opacity: .2 !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  opacity: .9;
  transition: all 100ms ease;
}

.swiper-button-next span,
.swiper-button-prev span {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.swiper-button-prev {
  left: -20px !important;
}

.swiper-button-prev span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name='Component 30 – 3' xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Ccircle data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg data-name='Group 335' fill='%23fff'%3E%3Cpath data-name='Rectangle 906' d='m18.85 25.379 8.812-8.812 2.203 2.203-8.812 8.812z'/%3E%3Cpath data-name='Rectangle 907' d='M21.053 23.188 29.865 32l-2.203 2.203-8.812-8.812z'/%3E%3C/g%3E%3C/svg%3E");
}

.swiper-button-next {
  right: -20px !important;
}

.swiper-button-next span {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Cg data-name='Component 30 – 4' transform='rotate(180 25 25)'%3E%3Ccircle data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg data-name='Group 335' fill='%23fff'%3E%3Cpath data-name='Rectangle 906' d='m19.85 25.379 8.812-8.812 2.203 2.203-8.812 8.812z'/%3E%3Cpath data-name='Rectangle 907' d='M22.053 23.188 30.865 32l-2.203 2.203-8.812-8.812z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.swiper .card-footer {
  background-color: #85b2d3;
  text-transform: uppercase;
  position: relative;
}

.swiper .card-footer button {
  background-color: #5f9ac6;
  border: 0;
  border-radius: 0;
  color: #fff;
  font-family: Lato, sans-serif;
  font-size: 1rem;
  font-weight: 900;
  letter-spacing: unset;
  padding: 1rem 1.25rem;
  position: relative;
  text-transform: uppercase;
  transition: all .5s ease;
  z-index: 1;
}

.swiper .card-footer button::after {
  background: #5f9ac6;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(35.5deg, 0deg);
  transform-origin: bottom right;
  transition: all .5s ease;
  width: 100%;
  z-index: -1;
}

.swiper .card:hover button::after {
  transform: skew(0deg, 0deg);
  transform-origin: bottom right;
}

#pdp-frontpage .swiper {
  width: 100%;
  height: 100%;
  padding: 0 0 60px 0 !important;
}
#pdp-frontpage .swiper-next {
   background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Cg data-name='Component 30 – 4' transform='rotate(180 25 25)'%3E%3Ccircle data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg data-name='Group 335' fill='%23fff'%3E%3Cpath data-name='Rectangle 906' d='m19.85 25.379 8.812-8.812 2.203 2.203-8.812 8.812z'/%3E%3Cpath data-name='Rectangle 907' d='M22.053 23.188 30.865 32l-2.203 2.203-8.812-8.812z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
 }
#pdp-frontpage .swiper-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg data-name='Component 30 – 3' xmlns='http://www.w3.org/2000/svg' width='50' height='50'%3E%3Ccircle data-name='Ellipse 23' cx='25' cy='25' r='25' fill='%233c3c3b'/%3E%3Cg data-name='Group 335' fill='%23fff'%3E%3Cpath data-name='Rectangle 906' d='m18.85 25.379 8.812-8.812 2.203 2.203-8.812 8.812z'/%3E%3Cpath data-name='Rectangle 907' d='M21.053 23.188 29.865 32l-2.203 2.203-8.812-8.812z'/%3E%3C/g%3E%3C/svg%3E");
}
#pdp-frontpage .swiper-next,
#pdp-frontpage .swiper-prev {
  position: absolute;
  width: 40px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  top: -6px;
}
#pdp-frontpage .swiper-next {
  right: -50px;
}
#pdp-frontpage .swiper-prev {
  position: absolute;
  left: -50px;
}

#pdp-frontpage .swiper-scrollbar {
  left: 60px;
  height: 20px !important;
  width: 70%;
  bottom: 12px;
  border-radius: 0;
}

#pdp-frontpage .swiper-scrollbar-drag {
  background: #e30613;
  border-radius: 0;
}