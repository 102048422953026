body {
  background: #F5F5F5;
  color: #0F0F0F;
  font-family: "Lato", sans-serif !important;
  background: $body-bg;
  font-weight: 300;
  background-size: 25%;
  line-height: 1.8;
  max-width: 100vw;
  overflow-x: hidden;
}

main {
  min-height: 600px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 900;
  line-height: 1.4;
}