body {
  text-size-adjust: 90%;
}
@media (max-width: 1199.98px) {
  .carousel-item:after {
    right: calc(40% + 90px);
  }
  .carousel-caption {
    font-size: 0.9rem;
    width: calc(50% - 60px);
  }
  .carousel-caption .h1 {
    font-size: 1.6rem !important;
  }
  .carousel-caption button {
    font-size: .9rem;
    padding: .8rem 1rem;
  }
}

@media (max-width: 991.98px) {

  #header-search button {
    font-size: 1.8rem;
  }
  .carousel-item:after {
    right: calc(30% + 90px);
  }
  .carousel-caption {
    font-size: 0.8rem;
    width: calc(50% - 60px);
  }
  .carousel-caption .h1 {
    font-size: 1.4rem !important;
  }
  .carousel-caption button {
    font-size: .9rem;
    padding: .4rem 1rem;
  }
  footer {
    font-size: 0.8rem;
  }
  #clp .card, #plp .card {
    flex: 1 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@media (max-width: 767.98px) {
  #header-search button {
    font-size: 1.6rem;
  }
  .carousel-item:after {
    right: calc(25% + 90px);
  }
  .carousel-caption {
    font-size: 0.8rem;
    width: calc(60% - 60px);
  }
  .carousel-caption .h1 {
    font-size: 1.3rem !important;
  }
  .carousel-caption button {
    font-size: .7rem;
    padding: .2rem .85rem;
  }
  .carousel-indicators {
    bottom: -10px;
  }
  #clp .card, #plp .card {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
  }
}

@media (max-width: 575.98px) {
  .carousel-item:after {
    right: calc(5% + 90px);
  }
  .carousel-caption {
    font-size: .5rem;
    width: calc(80% - 60px);
  }
  .carousel-caption .h1 {
    font-size: .6rem !important;
  }
  .carousel-caption button {
    font-size: .6rem;
    padding: .1rem .6rem;
  }
  .carousel-indicators {
    bottom: -18px;
  }
}

