// Body
$body-bg: #f5f5f5;

// Typography
$font-family-sans-serif: "Lato", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.8;

$font-weight-normal:          300;
$font-weight-bolder:          700;
$font-weight-bold:            900;

// Colors
$blue: #5f9ac6;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e30613;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$gray: #6f6f6e;

// Grid

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1500px
);

$grid-columns: 24;
$grid-row-columns: 12;
$grid-gutter-width-base: 30px;

// Custom
$body-color: #0F0F0F;
$link-color: #0F0F0F;
$link-hover-decoration: none;
$text-muted: #c4c4c4;
$component-active-bg: #fb6d76;
