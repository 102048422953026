.btn-search {
  background: #eaeaea;
  color: #0F0F0F;
  border-radius: 0;
  padding: 0 15px 0 0;
  height: 2.5rem !important;
  right: 0;
  top: 0;
}

.btn-custom {
  background: #5F9AC6;
  color: #FFFFFF;
  position: relative;
  transition: all .5s ease;
  border: 0;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: unset;
  padding: 1rem 1.25rem;
  text-transform: uppercase;
  z-index: 1;
}

.btn-custom:hover {
  background: #3C3C3B;
  color: #FFFFFF;
}

.btn-custom:focus {
  box-shadow: none;
}

.btn-custom:after {
  background: #5F9AC6;
  content: " ";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(-35.5deg, 0deg);
  transform-origin: bottom left;
  transition: all .5s ease;
  width: 100%;
  z-index: -1;
  outline: 1px solid transparent;
  will-change: transform;
}

.btn-custom:hover:after {
  background: #3C3C3B;
  transform-origin: bottom left;
  transform: skew(0deg, 0deg);
  transition: all .5s ease;
}

.btn-custom-sm {
  font-size: 0.8rem;
  padding: 0.8rem 1rem;
}

